'use client';

import React, { cloneElement } from 'react';
import useInspectorMode from '@src/hooks/useInspectorMode';
import { InspectorWrapperPropTypes } from './InspectorWrapper.types';
const InspectorWrapper: React.FC<InspectorWrapperPropTypes> = ({
  entryId,
  fieldId,
  children
}) => {
  const inspectorProps = useInspectorMode({
    entryId
  });

  // Clone the child element and inject inspectorProps
  return cloneElement(children, {
    ...children.props,
    ...inspectorProps({
      fieldId
    })
  });
};
export default InspectorWrapper;