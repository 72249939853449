'use client';

import { InspectorModeTags } from '@contentful/live-preview/dist/inspectorMode/types';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

interface UseInspectorModeParams {
  entryId: string;
  locale?: string;
}

interface InspectorPropsInput {
  fieldId: string;
}

/**
 * Hook to manage Contentful inspector mode in non-production environments.
 * Always calls the hook unconditionally to follow React's Rules of Hooks.
 * @param params - Parameters for configuring inspector mode.
 * @returns Inspector mode props in non-production, empty object in production.
 */
const useInspectorMode = ({ entryId, locale }: UseInspectorModeParams) => {
  const inspectorProps = useContentfulInspectorMode({ entryId, locale });

  // Disable inspector mode in production environments
  const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'live';

  return (props: InspectorPropsInput): InspectorModeTags | Record<string, never> => {
    if (isProduction) {
      return {}; // Empty object in production
    }
    return inspectorProps(props); // Actual inspector props in preview mode
  };
};

export default useInspectorMode;
