import React from 'react';
import NextLink from '../NextLink';
import ArrowForward from '@src/components/Icons/ArrowForward';
import { ButtonPropTypes } from './Button.types';
import InspectorWrapper from '../InspectorWrapper';
const Button: React.FC<ButtonPropTypes> = ({
  entryId = '',
  attr,
  containerClassName = '',
  btnClassName = '',
  btnText,
  primary,
  secondary,
  tertiary,
  quaternary,
  // Used for download files
  href,
  align = 'justify-center',
  handleOnClick,
  disabled,
  target = '_self',
  svgClassName = ''
}) => {
  return <InspectorWrapper entryId={entryId} fieldId='text' data-sentry-element="InspectorWrapper" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      <div className={`flex items-center ${align} ${containerClassName}`}>
        {primary && !href && <button data-test={attr} onClick={handleOnClick} title={disabled ? 'Button is disabled' : btnText} className={`h-[4.4rem] rounded-[0.6rem] bg-primary px-[2rem] text-center text-[1.6rem] font-[400] leading-[1.8rem] tracking-[-0.0304rem] text-[#F6F6F6] transition-colors duration-300 ease-in-out ${btnClassName} ${disabled ? 'pointer-events-none cursor-not-allowed border-[0.1rem] border-[#B9B9B9] !bg-background-primary !text-[#B9B9B9]' : 'common-btn-transition hover:bg-primary--v2'}`}>
            {btnText}
          </button>}

        {primary && href && <NextLink data-test={attr} href={href} title={disabled ? 'Button is disabled' : btnText} className={`flex h-[4.4rem] items-center justify-center rounded-[0.6rem] bg-primary px-[2rem] text-center text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-[#F6F6F6] transition-colors duration-300 ease-in-out ${btnClassName} ${disabled ? 'pointer-events-none cursor-not-allowed border-[0.1rem] border-[#B9B9B9] !bg-background-primary !text-[#B9B9B9]' : 'common-btn-transition hover:bg-primary--v2'}`}>
            {btnText}
            {btnText === 'Learn more' && <span className='screen-reader-text'>Please click here to learn more about our products</span>}
          </NextLink>}

        {secondary && !href && <button data-test={attr} onClick={handleOnClick} title={disabled ? 'Button is disabled' : btnText} className={`bg-transparent h-[4.4rem] rounded-[0.6rem] border-[0.1rem] border-primary px-[2rem] text-center text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-primary transition-colors duration-300 ease-in-out ${btnClassName} ${disabled ? 'pointer-events-none cursor-not-allowed border-[0.1rem] !border-[#B9B9B9] !bg-background-primary !text-[#B9B9B9]' : 'common-btn-transition hover:border-primary--v2 hover:text-primary--v2'}`}>
            {btnText}
          </button>}

        {secondary && href && <NextLink data-test={attr} href={href} title={disabled ? 'Button is disabled' : btnText} className={`bg-transparent flex h-[4.4rem] items-center justify-center rounded-[0.6rem] border-[0.1rem] border-primary px-[2rem] text-center text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-primary transition-colors duration-300 ease-in-out ${btnClassName} ${disabled ? 'pointer-events-none cursor-not-allowed border-[0.1rem] !border-[#B9B9B9] !bg-background-primary !text-[#B9B9B9]' : 'common-btn-transition hover:border-primary--v2 hover:text-primary--v2'}`}>
            {btnText}
            {btnText === 'Learn more' && <span className='screen-reader-text'>Please click here to learn more about our products</span>}
          </NextLink>}

        {tertiary && href && <NextLink data-test={attr} href={href} title={disabled ? 'Button is disabled' : btnText} className={`common-btn-transition bg-transparent flex items-center justify-center rounded-[0.6rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-primary transition-colors duration-300 ease-in-out ${btnClassName} ${disabled ? '!bg-transparent pointer-events-none cursor-not-allowed !text-[#B9B9B9]' : 'common-btn-transition hover:text-primary--v2'}`}>
            <span className={`mr-[0.8rem]`}>
              {btnText}{' '}
              {btnText === 'Learn more' && <span className='screen-reader-text'>Please click here to learn more about our products</span>}
            </span>
            <ArrowForward className={`mt-[0.1rem] h-[1.4rem] w-[0.646rem] text-primary hover:text-primary--v2 ${svgClassName} ${disabled ? 'pointer-events-none !text-[#B9B9B9]' : 'common-btn-transition'}`} />
          </NextLink>}

        {quaternary && href && <a data-test={attr} href={href} title={disabled ? 'Button is disabled' : btnText} target={target} className={`flex h-[4.4rem] items-center justify-center rounded-[0.6rem] bg-primary px-[2rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-[#F6F6F6] transition-colors duration-300 ease-in-out ${btnClassName} ${disabled ? 'pointer-events-none cursor-not-allowed border-[0.1rem] border-[#B9B9B9] !bg-background-primary !text-[#B9B9B9]' : 'common-btn-transition hover:bg-primary--v2'}`}>
            {btnText}
            {btnText === 'Learn more' && <span className='screen-reader-text'>Please click here to learn more about our products</span>}
          </a>}
      </div>
    </InspectorWrapper>;
};
export default Button;